import React from "react"
import {useIntl, FormattedMessage} from "gatsby-plugin-intl";

import Layout from "../components/layout"
import SEO from "../components/seo"
import "./contact.css"

const ContactPage = () => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "contact-title" })}/>
      <h1>
        <FormattedMessage id="contact-header" />
      </h1>
      <form
        className="contact-form"
        action="https://getform.io/f/400a4aab-86e0-4f6c-a931-080f1f0bd335"
        method="POST"
      >

        <label htmlFor="name"><FormattedMessage id="contact-form-name" /></label>
        <input type="text" name="name" id="name"/>
        <label htmlFor="email"><FormattedMessage id="contact-form-email" /></label>
        <input type="email" name="email" id="email" />
        <label htmlFor="tel"><FormattedMessage id="contact-form-tel" /></label>
        <input type="tel" name="tel" id="tel" />
        <label htmlFor="body"><FormattedMessage id="contact-form-body" /></label>
        <textarea name="body" id="body" rows={5} />

        <div className="cta">
          <button className="cta-button" type="submit"><FormattedMessage id="contact-form-send" /></button>
        </div>

      </form>
    </Layout>
  );
}

export default ContactPage;
